export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/iscrizione": [9],
		"/liceo/home": [10,[2,3]],
		"/liceo/home/annotazioni": [11,[2,3]],
		"/liceo/home/argomenti": [12,[2,3]],
		"/liceo/home/assenze": [13,[2,3]],
		"/liceo/home/certificati": [14,[2,3]],
		"/liceo/home/colloqui": [15,[2,3]],
		"/liceo/home/compiti": [16,[2,3]],
		"/liceo/home/impostazioni": [17,[2,3]],
		"/liceo/home/orario": [18,[2,3]],
		"/liceo/home/statistiche": [19,[2,3]],
		"/liceo/home/verifiche": [20,[2,3]],
		"/liceo/home/voti": [21,[2,3]],
		"/medie/home": [22,[4,5]],
		"/medie/home/argomenti": [23,[4,5]],
		"/medie/home/certificati": [24,[4,5]],
		"/medie/home/colloqui": [25,[4,5]],
		"/medie/home/compiti": [26,[4,5]],
		"/medie/home/impostazioni": [27,[4,5]],
		"/medie/home/orario": [28,[4,5]],
		"/medie/home/statistiche": [29,[4,5]],
		"/medie/home/verifiche": [30,[4,5]],
		"/medie/home/voti": [31,[4,5]],
		"/medie/preiscrizione": [32,[4]],
		"/preiscrizione": [33],
		"/protezionedati": [34],
		"/rubrica/home": [35,[6,7]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';